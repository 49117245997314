import { computed, readonly, type Ref } from "@vue/reactivity";
import { getCurrentInstance, onMounted, onUnmounted, ref, warn, watchEffect, type ComputedRef } from "vue";
import { EMPTY_OBJ } from '@vue/shared'

/**
 * An utility method for manually tracking and recomputing a given Vue3 computed reference.
 *
 * @template T
 * @param {ComputedRef<T>} computedRef - The computed reference to be tracked and recomputed.
 * @returns An object containing:
 *   - `computedRef`: A computed reference that updates whenever recompute is called.
 *   - `recompute`: A function to manually trigger recomputation.
 */
export function useManualTracking<T>(getter: () => T): { computedRef: ComputedRef<T>, recompute: () => void } {
  const counter = ref(0)
  const computedRef = computed(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    counter.value

    return getter()
  })

  return {
    recompute: () => void counter.value++,
    computedRef
  }
}

export function useIntervalTracking<T>(getter: () => T, ms: number): ComputedRef<T> {
  const counter = ref(0)
  const computedRef = computed(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    counter.value

    return getter()
  })

  let interval: NodeJS.Timeout | null = null

  const start = () => {
    interval ??= setInterval(() => counter.value++, ms)
  }

  const stop = () => {
    if (interval) {
      clearInterval(interval)
    }

    interval = null
  }

  onMounted(start)
  onUnmounted(stop)

  return computedRef
}

export function useInterval (
  callback: () => void,
  ms: number,
  options: {
    condition?: Ref<boolean>
  } = {}
) {
  let interval: NodeJS.Timeout

  const start = () => {
    if (interval) return
    if (typeof options.condition === 'undefined' || options.condition.value) {
      interval = setInterval(() => {
        if (typeof options.condition === 'undefined' || options.condition.value) {
          callback()
        }
      }, ms)
    }
  }

  const stop = () => {
    clearInterval(interval)
  }

  onMounted(start)
  onUnmounted(stop)

  if (options.condition) {
    watchEffect(() => {
      if (options.condition) {
        start()
      } else {
        stop()
      }
    })
  }

  return stop
}

export function useTemplateRef<T = unknown, Keys extends string = string>(
  key: Keys,
): Readonly<Ref<T | null>> {
  const i = getCurrentInstance()
  const r = ref(null)
  if (i) {
    const refs = i.refs === EMPTY_OBJ ? (i.refs = {}) : i.refs
    let desc: PropertyDescriptor | undefined
    if (
      import.meta.env.__DEV__ &&
      (desc = Object.getOwnPropertyDescriptor(refs, key)) &&
      !desc.configurable
    ) {
      warn(`useTemplateRef('${key}') already exists.`)
    } else {
      Object.defineProperty(refs, key, {
        enumerable: true,
        get: () => r.value,
        set: val => (r.value = val),
      })
    }
  } else if (import.meta.env.__DEV__) {
    warn('useTemplateRef() is called when there is no active component instance to be associated with.')
  }

  return import.meta.env.__DEV__ ? readonly(r) : r
}